import React from 'react';
import { AppContext } from '../App/AppProvider';

export default function({firstVisit}) {
    return (
        <AppContext.Consumer>
            {({firstVisit}) => firstVisit ?
                <div>
                    <h1>Welcome to Cryptocurrency Watcher</h1>
                    Please select your favorite currencies to watch.
                </div>
                : null
            }
        </AppContext.Consumer>
    );
  }