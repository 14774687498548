import React from 'react';
import WelcomeMessage from './WelcomeMsg';
import ConfirmBtn from './ConfirmBtn';
import Page from '../Shared/Page';
import CoinGrid from './CoinGrid';
import Search from './Search';

export default function() {
    return (
        <Page name='Settings'>
            <WelcomeMessage/>
            <CoinGrid topSection/>
            <ConfirmBtn/>
            <Search/>
            <CoinGrid/>
        </Page>
    )
}