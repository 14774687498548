import React from 'react';
import styled from 'styled-components';
import {AppContext} from '../App/AppProvider';
import {fontSize1, greenBoxShadow, color3} from '../Shared/Styles';

const ConfirmBtnStyle = styled.div`
    margin: 10px;
    padding: 10px;
    color: ${color3}
    ${fontSize1}
    cursor: pointer;
    &:hover { ${greenBoxShadow} }
`;

export const CenterDiv = styled.div`
    display: grid;
    justify-content: center;
`;

export default function() {
    return (
        <AppContext.Consumer>
            {({confirmFavorites}) => 
                <CenterDiv>
                    <ConfirmBtnStyle onClick={confirmFavorites}>
                        Confirm Favorites
                    </ConfirmBtnStyle>
                </CenterDiv>
            }
        </AppContext.Consumer>
    )
}