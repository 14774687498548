import React from 'react';
import './App.scss';
import AppLayout from './AppLayout';
import AppMenu from './AppMenu';
import {AppProvider} from './AppProvider';
import Settings from '../Settings';
import Dashboard from '../Dashboard';
import Content from '../Shared/Content';


function App() {
  return (
    <AppLayout>
      <AppProvider>
        <AppMenu/>
        <Content>
          <Settings/>
          <Dashboard/>
        </Content>
      </AppProvider>
    </AppLayout>
  );
}

export default App;
