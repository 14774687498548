import React from 'react';
import styled, {css} from 'styled-components';
import logoimage from './cryptowatcher-logo-40px.png';
import {AppContext} from './AppProvider';

const Menu = styled.div`
    display: grid;
    grid-template-columns: 40px 165px auto 100px 100px;
    margin-bottom: 40px;
`;

const Logo = styled.div`
    place-self: center;
    font-size: 1.5em;
    padding-left: 5px;
`;

const ControlButtonElm = styled.div`
    cursor: pointer;
    place-self: center;

    ${props => props.active && css`
        color: rgb(242, 225, 36);
        text-shadow: 0px 0px 33px rgba(255,255,0,1);
    `}

`;

function ControlButton({name}) {
    return (
        <AppContext.Consumer>
            {({firstVisit, page, setPage}) => (
                <ControlButtonElm
                    active={page === name}
                    onClick={()=> setPage(name)}
                >
                {name}
                </ControlButtonElm>
                )}
        </AppContext.Consumer>
    )
}

export default function() {
    return (
        <Menu>
            <div>
                <img src={logoimage} alt="Cryptocurrency Watcher Logo" width="40px" height="40px"/>
            </div>
            <Logo>
                CyrptoWatcher
            </Logo>
            <div></div>
            <ControlButton name="Dashboard" />
            <ControlButton name="Settings"/>
        </Menu>
    )
}